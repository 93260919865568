const appointment = [
  {
    path: '/appointments',
    name: 'appointments.list',
    component: () => import('@/views/appointment/AppointmentList.vue'),
    meta: {
      roles: ['super_admin', 'admin', 'doctor', 'user'],
    },
  },
]

export default appointment
