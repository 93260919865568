<template>
  <div v-if="visible" class="modal-overlay">
    <div class="modal-container text-center">
      <div v-html="message"></div>
      <b-button class="btn-success mr-1" @click="confirm">Confirmar
      </b-button>
      <b-button class="btn-danger" @click="cancel">Cancelar
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: ['visible', 'message'],
  methods: {
    confirm() {
      this.$emit('confirm')
    },
    cancel() {
      this.$emit('cancel')
    },
  },
}
</script>
  <style>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  .modal-container {
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  </style>
