<template>
  <b-card>
    <b-row align-v="center">
      <b-col class="pr-0" sm="8" md="6">
        <div>
          <b-button v-if="backButton" variant="flat-primary" class="btn-icon float-left" @click="onBack">
            <feather-icon icon="ArrowLeftIcon"/>
          </b-button>
          <h2>
            {{ this.name }}
          </h2>
        </div>
      </b-col>
      <b-col
        sm="4" md="6"
        class="text-right pl-0"
      >
        <slot />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: 'PageHeader',
  components: {},
  props: {
    name: {
      type: String,
      required: false,
      default: '',
    },
    backButton: {
      type: Boolean,
      default: false,
      required: false,

    },
  },
  data: () => ({
  }),
  mounted() {
  },
  methods: {
    onBack() {
      this.$router.back()
    },
  },
}
</script>

<style scoped>

</style>
