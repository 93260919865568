const unity = [
  {
    path: '/unities',
    name: 'unities.list',
    component: () => import('@/views/unity/UnityList.vue'),
    meta: {
      roles: ['super_admin', 'admin', 'doctor'],
    },
  },
]

export default unity
