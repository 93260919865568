const specialty = [
  {
    path: '/specialties',
    name: 'specialties.list',
    component: () => import('@/views/specialty/SpecialtyList.vue'),
    meta: {
      layout: 'full',
      public: true,
    },
  },
]

export default specialty
