const professional = [
  {
    path: '/professionals',
    name: 'professionals.list',
    component: () => import('@/views/professional/ProfessionalList.vue'),
    meta: {
      roles: ['super_admin', 'admin'],
    },
  },
  {
    path: '/home/professional',
    name: 'home.professional',
    component: () => import('@/views/professional/HomeProfessional.vue'),
    meta: {
      roles: ['doctor'],
    },
  },
]

export default professional
