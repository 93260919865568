<template class="grid">
  <b-media vertical-align="center">
    <b-card-header class="p-0">
      <h5>{{ data.start_date }} - {{ data.start_time }}</h5>

      <b-badge
          :variant="data.status.name === 'Confirmado' ? 'info'
          : data.status.name === 'Em Andamento' ? 'warning'
            : data.status.name === 'Aguardando Confirmação' ? 'info'
              : data.status.name === 'Concluído' ? 'success'
                : data.status.name === 'Desmarcado' ? 'danger'
                  : data.status.name === 'Remarcado' ? 'warning'
                    : 'default'"
          text-color="white"
      >
        {{ data.status.name }}
      </b-badge>
    </b-card-header>
    <hr>
    <b-row class="justify-content-center">
      <b-col sm="12" md="6">
        <template>
          <b-avatar
              class="float-left mr-1"
              style="position: relative"
              :text="avatarText(data.professional.name)"
              :src="data.professional.image_profile"
          />
        </template>

        <b>{{ data.professional.name }}</b>
        <p class="text-muted mb-0">
          {{ data.specialty.name }}-CRM: {{ data.professional.crm_state }}/{{
            data.professional.crm_code
          }}
        </p>
      </b-col>
      <b-col cols="12" sm="auto" class="d-flex justify-content-center flex-wrap flex-lg-nowrap flex-column flex-md-row">
        <b-badge
            class="m-2"
            :variant="data.modality ? 'success' : 'danger'"
            text-color="white"
        >
          {{ data.modality === true ? 'Online' : 'Presencial' }}
        </b-badge>
      </b-col>
      <b-col sm="12" md="4" class="text-center">
        <div v-if="data.status.name !== 'Concluído'">
          <b-button v-if="data.status.name === 'Em Andamento'" class="btn-success" @click="handleConfirm(data.uuid)">
            ACESSAR
          </b-button>
          <div
              v-else-if="data.status.name !== 'Confirmado' && data.status.name !== 'Em Andamento' && data.status.name !== 'Desmarcado' && data.status.name !== 'Remarcado'">
            <b-row class="justify-content-center">
              <b-col class="d-flex justify-content-center flex-wrap flex-lg-nowrap flex-column flex-md-row"
                     cols="12" lg="auto" md="auto">
                <b-button variant="primary" size="sm" class="mr-md-1 mb-2 mb-md-0" @click="showReag">Reagendar
                </b-button>
                <b-button variant="primary" size="sm" class="mr-md-1 mb-2 mb-md-0" @click="showUnmack">Desmarcar
                </b-button>
                <b-button variant="secondary" size="sm" class="mb-2 mb-md-0" @click="showPrint">Imprimir
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-right">
                <b-badge variant="info" class="mt-1">
                  Aguardando aprovação
                </b-badge>
              </b-col>

            </b-row>
          </div>
          <div
              v-else-if="data.status.name === 'Confirmado' && data.status.name !== 'Desmarcado' && data.status.name !== 'Remarcado'">
            <b-button variant="primary" class="mr-1" @click="showReag">Reagendar
            </b-button>
            <b-button variant="primary" class="mr-1" @click="showUnmack">Desmarcar
            </b-button>
            <b-button variant="primary" @click="showPrint">Imprimir
            </b-button>
            <br>
            <b-badge variant="success" class="mt-1">
              Aguarde a chamada do médico
            </b-badge>
          </div>

        </div>
      </b-col>
    </b-row>
    <modal
        :visible="isModalVisible"
        :message="message"
        @confirm="handleConfirm(data.uuid)"
        @cancel="handleCancel"
    />
    <modal
        :visible="isReagVisible"
        :message="message"
        @confirm="handleConfirmReag(data)"
        @cancel="handleCancel"
    />
    <modal
        :visible="isUnmarckVisible"
        :message="message"
        @confirm="handleConfirmUnmarck(data.uuid)"
        @cancel="handleCancel"
    />
    <modal
        :visible="isPrintVisible"
        :message="message"
        @confirm="handleConfirmPrint(data.uuid)"
        @cancel="handleCancel"
    />
  </b-media>
</template>

<script>
import {BAvatar, BMedia, BBadge} from 'bootstrap-vue'
import Modal from '@/components/app/Modal.vue'
import moment from 'moment-timezone'

export default {
  name: 'DsPatientGridCard',
  components: {
    BMedia,
    BAvatar,
    BBadge,
    Modal,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isModalVisible: false,
      isReagVisible: false,
      isUnmarckVisible: false,
      isPrintVisible: false,
      message: '',
      intervalId: null,
    }
  },
  mounted() {
    this.$echo.channel(`Appointment.${this.data.uuid}`).listen('StatusVerify', e => {
      if (e.data.status) {
        this.getStatus()
        if (e.data.status === 'em-andamento') {
          this.$notify.success('Médico está na sala de Telechamada!')
        }
      }
    })
  },
  beforeDestroy() {
    this.$echo.leave(`Appointment.${this.data.uuid}`)
  },
  methods: {
    async getStatus() {
      const response = await this.$http.get(`appointments/${this.data.uuid}/verify`)
      if (response.error) {
        this.$notify.error(response.error_message)
      }

      // eslint-disable-next-line vue/no-mutating-props
      this.data.status.name = response.status.name
    },
    avatarText(value) {
      if (!value) {
        return ''
      }
      const words = value.split(' ')
      const nameArray = [words[0]]
      if (words.length > 0) {
        nameArray.push(words[words.length - 1])
      }

      return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
    },
    showModal() {
      this.isModalVisible = true
      this.message = 'Você tem certeza que deseja abrir uma sala?'
    },
    showReag() {
      this.isReagVisible = true
      this.message = '<b>Você tem certeza que deseja reagendar a consulta?</b><br><br>Seu horário ficará disponivel para outros pacientes.<br><br>'
    },
    showUnmack() {
      this.isUnmarckVisible = true
      this.message = '<b>Você tem certeza que deseja desmarcar a consultad?</b><br><br>Seu horário ficará disponivel para outros pacientes.<br><br>'
    },
    showPrint() {
      this.isPrintVisible = true
      this.message = '<b>Você tem certeza que deseja imprimir a consulta?<br>'
    },
    handleConfirm(uuid) {
      this.isModalVisible = false
      this.$router.push({path: `/video-room/${uuid}`})
    },
    async handleConfirmReag(data) {
      this.isReagVisible = false

      sessionStorage.setItem('data_consulta', moment(new Date()).format('YYYY-MM-DD'))
      sessionStorage.setItem('modality', data.modality)
      sessionStorage.setItem('specialty_uuid', data.specialty.uuid)
      if (!Array.isArray(data.unity)) {
        sessionStorage.setItem('unity_uuid', data.unity.uuid)
      }
      sessionStorage.setItem('consulta_uuid_old', data.uuid)

      await this.$router.push({
        path: '/schedule/patient',
        query: {
          modality: data.modality,
          specialty_uuid: data.specialty.uuid,
          unity_uuid: Array.isArray(data.unity) ? null : data.unity.uuid,
          ...this.$route.query,
        },
      })
    },
    async handleConfirmUnmarck(uuid) {
      this.isUnmarckVisible = false

      let response = {}
      response = await this.$http.post(`appointments/${uuid}/unmarcked`)
      if (response.error) {
        this.$notify.error(response.error_message)
        return
      }
      this.$notify.success('Consulta desmarcada com sucesso.')
      window.location.reload()
    },
    handleCancel() {
      this.isModalVisible = false
      this.isReagVisible = false
      this.isUnmarckVisible = false
    },
    async handleConfirmPrint(uuid) {
      this.isPrintVisible = false

      const response = await this.$http.get(`appointments/${uuid}/generate-pdf`, {responseType: 'blob'})
      const blob = new Blob([response], {type: 'application/pdf'})
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `consulta-${uuid}.pdf`
      link.click()
      this.$notify.success('Download efetuado.')
    },
  },
}
</script>

<style scoped>
.grid {
  position: relative;
  z-index: 1; /* Ou outro valor, mas menor que o do modal */
  /* Outras propriedades do grid */
}
</style>
