const patient = [
  {
    path: '/patients',
    name: 'patients.list',
    component: () => import('@/views/patient/PatientList.vue'),
    meta: {
      roles: ['super_admin', 'admin', 'doctor'],
    },
  },
  {
    path: '/home/patient',
    name: 'home.patient',
    component: () => import('@/views/patient/HomePatient.vue'),
    meta: {
      roles: ['user'],
    },
  },
  {
    path: '/scheduling/patient',
    name: 'scheduling.patient',
    component: () => import('@/views/appointment/scheduling/SpecialtyScheduling.vue'),
  },
  {
    path: '/schedule/patient',
    name: 'schedule.patient',
    component: () => import('@/views/appointment/scheduling/Schedule.vue'),
    props: true,
  },
  {
    path: '/appointments/patient',
    name: 'appointments.patient',
    component: () => import('@/views/appointment/AppointmentList.vue'),
    meta: {
      roles: ['user'],
    },
  },
]

export default patient
