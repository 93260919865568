const operator = [
  {
    path: '/operators',
    name: 'operators.list',
    component: () => import('@/views/operator/OperatorList.vue'),
    meta: {
      roles: ['super_admin', 'admin'],
    },
  },
]

export default operator
