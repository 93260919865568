// Mixin de inatividade para Vue 2
const InactivityMixin = {
    data() {
        return {
            inactivityTime: 900000, // 15 minutos em milissegundos
            inactivityTimeout: null,
            isActive: true // Estado inicial do usuário
        };
    },
    methods: {
        resetInactivityTimeout() {
            clearTimeout(this.inactivityTimeout);
            this.inactivityTimeout = setTimeout(this.handleInactivity, this.inactivityTime);

            if (!this.isActive) {
                this.isActive = true;
                this.$emit('UserOnline'); // Emite o evento UserOnline quando o usuário estiver ativo
            }
        },
        pauseInactivityTimeout() {
           // clearTimeout(this.inactivityTimeout);
        },
        handleInactivity() {
            if (!document.hidden) {
                return
            }
            if (this.isActive) {
                this.isActive = false;
                this.$emit('UserInactive'); // Emite o evento UserInactive quando o usuário estiver inativo
            }
        },
        handleVisibilityChange() {
            if (document.hidden) {
                this.pauseInactivityTimeout();
                return;
            }
            this.resetInactivityTimeout();
        },
    },
    mounted() {
        /*
        ['mousemove', 'keydown', 'click', 'scroll', 'touchstart', 'touchmove'].forEach(event => {
            window.addEventListener(event, this.resetInactivityTimeout);
        });

       */
        document.addEventListener('visibilitychange', this.handleVisibilityChange);
        this.resetInactivityTimeout();
    },
    beforeDestroy() {
        /*
        ['mousemove', 'keydown', 'click', 'scroll', 'touchstart', 'touchmove'].forEach(event => {
            window.removeEventListener(event, this.resetInactivityTimeout);
        });
         */
        document.removeEventListener('visibilitychange', this.handleVisibilityChange);
        clearTimeout(this.inactivityTimeout);
    }
};

export default InactivityMixin;
