const statusAppointments = [
  {
    path: '/status-appointments',
    name: 'status-appointments.list',
    component: () => import('@/views/statusappointments/StatusAppointmentsList.vue'),
    meta: {
      roles: ['super_admin', 'admin'],
    },
  },
]

export default statusAppointments
