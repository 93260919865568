const videoroom = [
  {
    path: '/video-room/:uuid',
    name: 'video.room',
    component: () => import('@/views/videoroom/Room.vue'),
    meta: {
      layout: 'full',
      roles: ['super_admin', 'admin', 'doctor', 'user'],
    },
  },
]

export default videoroom
