const payments = [
  {
    path: '/payment',
    name: 'payment',
    component: () => import('@/views/appointment/scheduling/Payment.vue'),
    meta: {
      roles: ['user'],
    },
  },
  {
    path: '/payment/:uuid',
    name: 'payment.confirm',
    props: true,
    component: () => import('@/views/appointment/scheduling/Payment.vue'),
    meta: {
      roles: ['user'],
    },
  },
]

export default payments
